import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyChCnfl1YT9fULfiR41hKSeKE5VmYxSjUM",
  authDomain: "lappa-fett.firebaseapp.com",
  projectId: "lappa-fett",
  storageBucket: "lappa-fett.appspot.com",
  messagingSenderId: "84169739381",
  appId: "1:84169739381:web:3199fb9c2547755d8fbc3c"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

